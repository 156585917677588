import { css } from "styled-components";
import colors from "./_colors";
import fonts from "./_fonts";

export default {
  colors,
  fonts,
  presets: {
    controlButton: css`
      border-radius: 8px;
      border: solid 1px #e4e8ed;
      background-color: #fcfcff;
      transition-duration: 75ms;
      transition-timing-function: ease-in;
      transition-property: border-color;
      will-change: border-color;
      cursor: pointer;
      padding: 0 2px;

      &:hover,
      &:focus {
        border-color: rgba(6, 25, 87, 0.3);
      }
    `,
  },
};
