import React, { createContext, ReactNode, useState } from "react";
import { useRouter } from "next/router";

type View = "admin" | "user";

type ViewContextProps = {
  viewToggleDisabled: boolean;
  view: View;
  toggleView: () => void;
};

const view: View = "admin";

const ViewContext = createContext<ViewContextProps>({
  viewToggleDisabled: true,
  view,
  toggleView: () => {},
});

const allowedPaths = [
  /^\/app\/items$/,
  /^\/app\/items\/edit\/\[id\]$/,
  /^\/app\/content\/projects\/edit\/\[id\]$/,
];

function ViewProvider({ children }: { children: ReactNode }): JSX.Element {
  const [view, setView] = useState<View>("admin");
  const router = useRouter();

  const currentPath: string = router.pathname;

  let viewToggleDisabled = true;
  for (let i = 0; i < allowedPaths.length; i++) {
    const re = allowedPaths[i];

    if (re.test(currentPath)) {
      viewToggleDisabled = false;
      break;
    }
  }

  function toggleView() {
    const newView = view === "admin" ? "user" : "admin";

    setView(newView);
  }

  return (
    <ViewContext.Provider value={{ view, toggleView, viewToggleDisabled }}>
      {children}
    </ViewContext.Provider>
  );
}

export { ViewProvider, ViewContext as default };
