const presetColors = {
  GRAY_1000: "hsl(210, 24%, 16%)",
  GRAY_900: "hsl(209, 20%, 25%)",
  GRAY_800: "hsl(209, 18%, 30%)",
  GRAY_700: "hsl(209, 14%, 37%)",
  GRAY_600: "hsl(211, 12%, 43%)",
  GRAY_500: "hsl(211, 10%, 53%)",
  GRAY_400: "hsl(211, 13%, 65%)",
  GRAY_300: "hsl(210, 16%, 82%)",
  GRAY_200: "hsl(214, 15%, 91%)",
  GRAY_100: "hsl(216, 33%, 97%)",
  GRAY_50: "hsl(216, 33%, 99%)",
  INDIGO_1000: "hsl(234, 62%, 26%)",
  INDIGO_900: "hsl(232, 51%, 36%)",
  INDIGO_800: "hsl(230, 49%, 41%)",
  INDIGO_700: "hsl(228, 45%, 45%)",
  INDIGO_600: "hsl(227, 42%, 51%)",
  INDIGO_500: "hsl(227, 50%, 59%)",
  INDIGO_400: "hsl(225, 57%, 67%)",
  INDIGO_300: "hsl(224, 67%, 76%)",
  INDIGO_200: "hsl(221, 78%, 86%)",
  INDIGO_100: "hsl(221, 68%, 93%)",
  INDIGO_50: "hsl(221, 68%, 98%)",
  BLUE_1000: "hsl(200, 82%, 24%)",
  BLUE_900: "hsl(200, 72%, 31%)",
  BLUE_800: "hsl(200, 68%, 35%)",
  BLUE_700: "hsl(200, 59%, 43%)",
  BLUE_600: "hsl(200, 54%, 49%)",
  BLUE_500: "hsl(200, 60%, 58%)",
  BLUE_400: "hsl(200, 66%, 69%)",
  BLUE_300: "hsl(200, 71%, 80%)",
  BLUE_200: "hsl(200, 88%, 90%)",
  BLUE_100: "hsl(201, 100%, 96%)",
  BLUE_50: "hsl(201, 100%, 98%)",
  GREEN_1000: "hsl(125, 97%, 14%)",
  GREEN_900: "hsl(125, 86%, 20%)",
  GREEN_800: "hsl(125, 79%, 26%)",
  GREEN_700: "hsl(122, 80%, 29%)",
  GREEN_600: "hsl(122, 73%, 35%)",
  GREEN_500: "hsl(123, 57%, 45%)",
  GREEN_400: "hsl(123, 53%, 55%)",
  GREEN_300: "hsl(124, 63%, 74%)",
  GREEN_200: "hsl(127, 65%, 85%)",
  GREEN_100: "hsl(125, 65%, 93%)",
  GREEN_50: "hsl(125, 65%, 96%)",
  RED_1000: "hsl(348, 94%, 20%)",
  RED_900: "hsl(350, 94%, 28%)",
  RED_800: "hsl(352, 90%, 35%)",
  RED_700: "hsl(354, 85%, 44%)",
  RED_600: "hsl(356, 75%, 53%)",
  RED_500: "hsl(360, 83%, 62%)",
  RED_400: "hsl(360, 91%, 69%)",
  RED_300: "hsl(360, 100%, 80%)",
  RED_200: "hsl(360, 100%, 87%)",
  RED_100: "hsl(360, 100%, 95%)",
  RED_50: "hsl(360, 100%, 98%)",
  ORANGE_1000: "hsl(6, 96%, 26%)",
  ORANGE_900: "hsl(8, 92%, 35%)",
  ORANGE_800: "hsl(10, 93%, 40%)",
  ORANGE_700: "hsl(12, 86%, 47%)",
  ORANGE_600: "hsl(14, 89%, 55%)",
  ORANGE_500: "hsl(16, 94%, 61%)",
  ORANGE_400: "hsl(18, 100%, 70%)",
  ORANGE_300: "hsl(20, 100%, 77%)",
  ORANGE_200: "hsl(22, 100%, 85%)",
  ORANGE_100: "hsl(24, 100%, 93%)",
  ORANGE_50: "hsl(24, 100%, 98%)",
  YELLOW_1000: "hsl(15, 86%, 30%)",
  YELLOW_900: "hsl(22, 82%, 39%)",
  YELLOW_800: "hsl(29, 80%, 44%)",
  YELLOW_700: "hsl(36, 77%, 49%)",
  YELLOW_600: "hsl(42, 87%, 55%)",
  YELLOW_500: "hsl(44, 92%, 63%)",
  YELLOW_400: "hsl(48, 94%, 68%)",
  YELLOW_300: "hsl(48, 95%, 76%)",
  YELLOW_200: "hsl(48, 100%, 88%)",
  YELLOW_100: "hsl(49, 100%, 96%)",
  YELLOW_50: "hsl(49, 100%, 98%)",
};

const PRIMARY = presetColors.INDIGO_900;
const SECONDARY = presetColors.BLUE_600;
const LIGHT_GRAY = presetColors.GRAY_200;
const GRAY = presetColors.GRAY_100;
const DARK_GRAY = presetColors.GRAY_600;
const GREEN = presetColors.GREEN_300;
const GREEN_DARKER = presetColors.GREEN_500;
const ORANGE_LIGHT = presetColors.ORANGE_200;
const ORANGE = presetColors.ORANGE_400;
const SALMON = presetColors.RED_400;
const SALMON_LIGHT = presetColors.RED_200;
const BLUE_DARK = "#061236";
const BLUE_BRIGHT = presetColors.INDIGO_900;
const WHITE = "hsl(0, 0%, 100%)";
const BLACK = "hsl(0, 0%, 0%)";
const RED = presetColors.RED_200;
const RED_BRIGHT = presetColors.RED_600;
export const FILTERS_BACKGROUND = "#f0f4f8";

export default {
  ...presetColors,
  primary: PRIMARY,
  secondary: SECONDARY,
  light_gray: LIGHT_GRAY,
  gray: GRAY,
  dark_gray: DARK_GRAY,
  blue_dark: BLUE_DARK,
  blue_bright: BLUE_BRIGHT,
  red: RED,
  red_bright: RED_BRIGHT,

  // Buttons
  button_border_light: GRAY,
  button_white: WHITE,
  button_light: presetColors.GRAY_100,
  button_text: presetColors.INDIGO_1000,

  // Text
  text_dark: presetColors.GRAY_1000,
  text_dark_blue: presetColors.INDIGO_1000,
  text_white: WHITE,
  text_light: "rgba(8, 19, 47, 0.75)",
  text_ultra_light: presetColors.GRAY_500,

  // HEADER
  header_link: PRIMARY,
  header_link_border: PRIMARY,
  header_link_main: "#021436",
  header_link_bottom: "#001947",

  // Background
  login_layout_background: presetColors.INDIGO_100,
  app_background: FILTERS_BACKGROUND,

  // Icons
  icon_background: WHITE,

  // Cards
  card_background: WHITE,

  // FormStyles
  label_text: presetColors.INDIGO_1000,
  label_text_light: presetColors.GRAY_500,

  // TABLE
  table_controls: presetColors.GRAY_800,
  table_text: presetColors.GRAY_800,
  table_heading: "RGBA(6, 18, 54, 0.52);",

  // Select Input
  default__colored: presetColors.GRAY_100, //
  default__colored_text: "#313131",

  // Input
  input_text__idle: presetColors.GRAY_800,
  input_text__active: "RGBA(8, 19, 47, 0.82)",
  input__outline: "RGBA(229, 151, 0)",

  // FORM INPUTS
  input_placeholder: presetColors.GRAY_400,

  // ITEM STATUS
  status__DRAFT: ORANGE_LIGHT,
  status__DRAFT_text: ORANGE,
  status__DROPPED: SALMON_LIGHT,
  status__DROPPED_text: SALMON,
  status__FAILED: SALMON_LIGHT,
  status__FAILED_text: SALMON,
  status__FINAL: presetColors.GREEN_300,
  status__FINAL_text: presetColors.GREEN_800,
  status__NLUC: SALMON_LIGHT,
  status__NLUC_text: SALMON,

  // USER ROLES
  role__ADMIN: SALMON_LIGHT,
  role__ADMIN_text: SALMON,
  role__RESEARCHER: presetColors.INDIGO_200,
  role__RESEARCHER_text: presetColors.INDIGO_600,
  role__REGISTERED: presetColors.GREEN_300,
  role__REGISTERED_text: presetColors.GREEN_800,
  // gray because depracated roles
  role__TEACHER: presetColors.GRAY_100,
  role__TEACHER_text: presetColors.GRAY_600,
  role__STUDENT: presetColors.GRAY_100,
  role__STUDENT_text: presetColors.GRAY_600,

  // MISC
  error: presetColors.RED_600,
  success: presetColors.GREEN_500,

  // TEST STATUS
  test__status__DRAFT: ORANGE_LIGHT,
  test__status__DRAFT_text: ORANGE,
  test__status__WAITING: ORANGE_LIGHT,
  test__status__WAITING_text: ORANGE,
  test__status__READY_FOR_TESTING: GREEN,
  test__status__READY_FOR_TESTING_text: GREEN_DARKER,
  test__status__FINAL: SALMON_LIGHT,
  test__status__FINAL_text: SALMON,

  status__PUBLIC: GREEN,
  status__PUBLIC_text: GREEN_DARKER,
  status__INTERNAL: ORANGE_LIGHT,
  status__INTERNAL_text: ORANGE,
  status__PRIVATE: ORANGE_LIGHT,
  status__PRIVATE_text: ORANGE,

  // TESTRESPONSE STATUS
  testResponse_status__UNCHECKED: GRAY,
  testResponse_status__UNCHECKED_text: DARK_GRAY,
  testResponse_status__UNFINISHED: DARK_GRAY,
  testResponse_status__UNFINISHED_text: LIGHT_GRAY,
  testResponse_status__CHECKING: ORANGE_LIGHT,
  testResponse_status__CHECKING_text: ORANGE,
  testResponse_status__CHECKED: GREEN,
  testResponse_status__CHECKED_text: GREEN_DARKER,

  SEARCH_INPUT_BORDER: "hsl(227, 18%, 79%)",

  //
  info: presetColors.INDIGO_700,
  warn: presetColors.ORANGE_500,
  alert: presetColors.RED_400,
};
