import React, { useReducer, createContext, useContext } from "react";
// import * as Sentry from "@sentry/node";
import Router from "next/router";
import getCurrentUserFromApi from "modules/user/methods/getCurrentUser";
import { CurrentUser } from "modules/user/types";
import { AuthCredentials, AuthActionType, AuthState } from "./authContext.d";
import authReducer from "./reducer";

const initialState: AuthState = {
  checkedOnFirstLoad: false,
  user: null,
  isLoggedIn: false,
  error: false,
  loading: false,
  login: () => {},
  logout: () => {},
  setCurrentUser: () => {},
};

const AuthContext = createContext(initialState);

function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  // @ts-ignore
  const [state, dispatch] = useReducer(authReducer, { initialState });

  async function login(credentials: AuthCredentials) {
    if (state.isLoggedIn) dispatchError("User already logged in");

    dispatchLoading(true);

    const response = await sendLoginReq(credentials);
    dispatchLoading(false);
    if (response.status === 200) {
      dispatchLogin();
      dispatchCurrentUser(await getCurrentUser());
      return Promise.resolve("success");
    } else {
      dispatchError(await response.text());
      return Promise.resolve("failed");
    }
  }

  async function logout() {
    if (!state.isLoggedIn) {
      dispatchError("User not logged in");
    }

    dispatchLoading(true);

    await fetch("/api/logout", {
      method: "POST",
    });

    dispatchLoading(false);
    dispatchLogout();

    Router.push("/app/sign-in");
  }

  function setCurrentUser(user: CurrentUser) {
    dispatchCurrentUser(user);
  }

  async function getCurrentUser() {
    try {
      return await getCurrentUserFromApi({});
    } catch (err) {
      return null;
    }
  }

  // React.useEffect(
  //   function updateSentryContext() {
  //     Sentry.setContext("user", {
  //       role: state?.user?.role || "unknown",
  //     });
  //   },
  //   [state],
  // );

  return (
    <AuthContext.Provider value={{ ...state, login, logout, setCurrentUser }}>
      {children}
    </AuthContext.Provider>
  );

  // DISPATCHERS
  function dispatchLogin(payload?: CurrentUser) {
    log(AuthActionType.LOGIN, true);
    // @ts-ignore
    dispatch({ type: AuthActionType.LOGIN, payload });
  }
  function dispatchLogout() {
    log(AuthActionType.LOGOUT, true);
    // @ts-ignore
    dispatch({ type: AuthActionType.LOGOUT });
  }
  function dispatchLoading(payload: boolean) {
    log(AuthActionType.LOADING, payload);
    // @ts-ignore
    dispatch({ type: AuthActionType.LOADING, payload });
  }
  function dispatchError(payload: string) {
    log(AuthActionType.ERROR, payload);
    // @ts-ignore
    dispatch({ type: AuthActionType.ERROR, payload });
  }
  function dispatchCurrentUser(payload: CurrentUser | null) {
    log(AuthActionType.CURRENT_USER, !!payload);
    // @ts-ignore
    dispatch({ type: AuthActionType.CURRENT_USER, payload });
  }

  // HELPERS
  async function sendLoginReq(credentials: AuthCredentials) {
    return fetch("/api/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(credentials),
    });
  }
}

function log(action: string, payload?: any) {
  console.log(action + ": " + payload);
}

const useAuth = () => useContext(AuthContext);

const useCurrentUser = () => {
  const { user: currentUser } = useAuth();
  return currentUser;
};

export { AuthProvider, AuthContext as default, useAuth, useCurrentUser };
