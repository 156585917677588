import { AuthState, AuthActionType, AuthAction } from "./authContext.d";

function authReducer(state: AuthState, action: AuthAction) {
  switch (action.type) {
    case AuthActionType.LOGIN:
      return {
        ...state,
        isLoggedIn: true,
        error: null,
        user: action.payload,
      };
    case AuthActionType.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        error: null,
        user: null,
      };
    case AuthActionType.ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case AuthActionType.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case AuthActionType.CURRENT_USER:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: !!action.payload,
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
}

export default authReducer;
